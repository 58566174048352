.quill {
  height: 100%;
  overflow-y: hidden;
}

.ql-snow.ql-toolbar {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: white;
  border-top: none;
  border-right: none;
  border-left: none;
}

.ql-container.ql-snow {
  height: 100%;
  overflow-y: auto;
  border-radius: 16px;
  background-color: white;
  border: none;
}
